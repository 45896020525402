._2ZFtT9ivzTs84kxm7fR5bs {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
  text-align: center;
  overflow: hidden; }
  @media (max-width: 767px) {
    ._2ZFtT9ivzTs84kxm7fR5bs {
      margin: 0; } }
  @media (min-width: 1200px) {
    ._2ZFtT9ivzTs84kxm7fR5bs {
      max-width: 1149px;
      min-width: 1149px;
      margin: 0 auto; } }

._2S0LHA9qzt6UZAeZc0Sb4y {
  position: relative;
  width: 100%;
  padding: 0 30px 0 20px; }
  @media (max-width: 767px) {
    ._2S0LHA9qzt6UZAeZc0Sb4y {
      padding: 0; } }

._3g762kIzszIUjI0XF0Q2oq {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  transform: translateY(-50%);
  font-size: 32px;
  left: 0;
  z-index: 100; }
  ._3g762kIzszIUjI0XF0Q2oq:focus {
    outline: 0; }
  ._3g762kIzszIUjI0XF0Q2oq:hover {
    opacity: 1; }

._1B7MyARYGaBBYL5chJQnKS {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  font-size: 32px;
  transform: translateY(-50%);
  right: 0;
  z-index: 100; }
  ._1B7MyARYGaBBYL5chJQnKS:focus {
    outline: 0; }
  ._1B7MyARYGaBBYL5chJQnKS:hover {
    opacity: 1; }

._1S2pRyGXE9gcp4ReG9shBz {
  display: none; }

.MRO_Vhc0mUmXH0trQC5l4 {
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-end;
  transition-timing-function: linear; }

._3zMapSuulIWEbREQHt1UrS {
  display: none; }

@media (max-width: 767px) {
  ._1S2pRyGXE9gcp4ReG9shBz {
    display: flex;
    margin: 0 4px;
    cursor: pointer; }
    ._1S2pRyGXE9gcp4ReG9shBz a {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #111;
      opacity: .2;
      transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1); }
    ._1S2pRyGXE9gcp4ReG9shBz a:hover {
      opacity: 1;
      background: #1075b1; }
    ._1opp4CEhFZGQl8-XOjKnLG {
      opacity: .9;
      display: flex;
      margin: 0 4px;
      cursor: pointer; }
      ._1opp4CEhFZGQl8-XOjKnLG a {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
        opacity: 1;
        background: #01A3E5; }
  ._3zMapSuulIWEbREQHt1UrS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin: 20px 0;
    padding: 0; }
  ._1B7MyARYGaBBYL5chJQnKS, ._3g762kIzszIUjI0XF0Q2oq {
    display: none; } }

.fgBUbNtzK6MIgGfiazkLT {
  text-align: left; }

._9sTubjbpxU2SuoupGN-XA {
  text-align: center; }

._1KiGT9ZBejhOUQtxrunMcO {
  text-align: right; }

._3vwLmzMJE0J6NY_Rt0oesK {
  margin-top: 15px; }

._1Y5jS-hQyLIt8RiRH42gDv {
  margin-top: 15px; }
  ._1Y5jS-hQyLIt8RiRH42gDv ._3AWyVfSehF8oYHZimz__ST {
    line-height: 24px;
    color: black;
    margin: 0; }

._1RS7ss9YMeZY5Z7Il-G2Y6 {
  cursor: pointer; }

/* Hide the images by default */
._1GWLvBonhzxmN-Xk_NxADE {
  display: none; }
  @media (max-width: 767px) {
    ._1GWLvBonhzxmN-Xk_NxADE {
      display: block; } }

._1uGFysjIx_g-w6OFWREuAI {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px; }

._22U_gR_NcRtX0ejX1kmArp {
  height: 1px;
  border-bottom: 0.5px solid #0074B3;
  width: 150px;
  margin: auto;
  margin-bottom: 20px; }

._1PSawzuoddKpwI6QQyjjyv {
  position: relative; }

.qG-QMR0GYXltFV4VHFSx0 {
  margin-top: 25px;
  height: 200px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 767px) {
    .qG-QMR0GYXltFV4VHFSx0 {
      height: 400px;
      max-width: 400px;
      display: block;
      margin: 0 auto; } }

._3A0cgOrXqbtEqguc2ch1Rt {
  display: flex;
  align-content: stretch; }
  ._3A0cgOrXqbtEqguc2ch1Rt a {
    text-decoration: none;
    color: #687689; }
    ._3A0cgOrXqbtEqguc2ch1Rt a:hover {
      text-decoration: none;
      color: #687689; }
  ._3A0cgOrXqbtEqguc2ch1Rt .dih9Z4jW3shd5bmSwHMBt {
    min-width: calc(30vw - 50px) !important;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
    transition: transform ease-in-out 0.25s; }
    @media (min-width: 1200px) {
      ._3A0cgOrXqbtEqguc2ch1Rt .dih9Z4jW3shd5bmSwHMBt {
        width: 340px !important;
        min-width: 340px !important; } }
    @media (max-width: 767px) {
      ._3A0cgOrXqbtEqguc2ch1Rt .dih9Z4jW3shd5bmSwHMBt {
        min-width: 100% !important;
        margin: 0; }
        ._3A0cgOrXqbtEqguc2ch1Rt .dih9Z4jW3shd5bmSwHMBt img {
          width: 100%; } }
    @media (max-width: 500px) {
      ._3A0cgOrXqbtEqguc2ch1Rt .dih9Z4jW3shd5bmSwHMBt {
        padding: 0 2px 0 2px; } }

._1RFTfggmvmzI-wILD_EG- {
  width: 100%;
  height: 200px; }

div.UVcPBT8UdyKqkJb9_f9n- {
  text-align: left; }

div._2DZcNEWMOCYh51aX5CtB70 {
  text-align: center; }

div._1NrCC1JGWzjFNPXtyGMga0 {
  text-align: right; }

._1XnlHI0dCS-vL7qTv4rjyB {
  background: #065784;
  color: #fff !important;
  font-family: Lato, sans-serif;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc;
  height: 44px; }
  ._1XnlHI0dCS-vL7qTv4rjyB:hover {
    background: #1084b5; }

._2DG_eFCJgPXI1JIdDvq04w {
  font-weight: bolder;
  font-size: 1rem;
  margin: 0 5px; }

._615oYIbSPWZ2UJUNmOKrf {
  color: #aaa;
  font-weight: bold; }
  ._615oYIbSPWZ2UJUNmOKrf ._2-hPyA3qBUqhXSXKPUoamb {
    color: #aaa;
    text-decoration: line-through;
    margin: 0 5px; }
    ._615oYIbSPWZ2UJUNmOKrf ._2-hPyA3qBUqhXSXKPUoamb span {
      color: #aaa; }

._2JAIvX-QQQ3vvHGnA0B9Z2 {
  font-weight: bold; }

._1-qopNp3mrCf4AmZwvjh2F {
  margin: 15px 0;
  font-weight: bold;
  text-align: center; }

._24aa6MDQCws7LH9EzN37Q0 {
  display: none; }

._25hPP8Atfl7s4pMH67qtsx {
  width: 50%;
  padding: 12px; }

._30kANlNk31YQvHbm4TpjME {
  text-align: center;
  margin-top: 12px;
  margin-right: 10px;
  height: 50px; }
  @media (max-width: 767px) {
    ._30kANlNk31YQvHbm4TpjME {
      width: 50%;
      min-width: 240px;
      margin: 10px auto 0; } }
