._3eQlGqGaoM_xeQ6WqPfJ-2 {
  position: absolute;
  width: 100%;
  opacity: 0; }

._1J3aZbmsAeuewL3_w-l9Ro {
  position: relative; }

._1jXIiZEGNffj-wf_LJwD_j {
  opacity: 1; }

/* Fading animation */
._2Aj1gg0yFAGHCefHJgQal5.Yu2kBEHFI_Gb5nJ3Kcgdc {
  opacity: 1;
  z-index: 2;
  -webkit-animation-name: _35Qlj1aK-Du_vBB2xoM7Mp;
  -webkit-animation-duration: 1s;
  animation-name: _35Qlj1aK-Du_vBB2xoM7Mp;
  animation-duration: 1s; }

@-webkit-keyframes _35Qlj1aK-Du_vBB2xoM7Mp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _35Qlj1aK-Du_vBB2xoM7Mp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

._2Aj1gg0yFAGHCefHJgQal5.NBjN7T8MVignDSmNE2k5e {
  -webkit-animation-name: _290SI0a628lI_Z5FUXWKYZ;
  -webkit-animation-duration: 0.3s;
  animation-name: _290SI0a628lI_Z5FUXWKYZ;
  animation-duration: 0.3s; }

@-webkit-keyframes _290SI0a628lI_Z5FUXWKYZ {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _290SI0a628lI_Z5FUXWKYZ {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/* Sticky styles */
._3qOcPWdzoJ8gn9EXZWMTWT {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10; }

/* Vertical scrolling animation */
.OwEkG4Dj5X6INg8ul7KsS {
  transform: translateY(-100%); }

.OwEkG4Dj5X6INg8ul7KsS.Yu2kBEHFI_Gb5nJ3Kcgdc {
  transition: 1s ease-in-out;
  opacity: 1;
  transform: translateY(0); }

.OwEkG4Dj5X6INg8ul7KsS.NBjN7T8MVignDSmNE2k5e {
  -webkit-animation-name: _3tQ0h8wA2ncd9e7A1gbY6H;
  -webkit-animation-duration: 1.5s;
  animation-name: _3tQ0h8wA2ncd9e7A1gbY6H;
  animation-duration: 1.5s; }

@keyframes _3tQ0h8wA2ncd9e7A1gbY6H {
  0% {
    opacity: 1;
    transform: translateY(0); }
  50% {
    opacity: 0.5;
    transform: translateY(100%); }
  100% {
    opacity: 0;
    transform: translateY(100%); } }

/* Horizontal scrolling animation */
._20JrsyiDoBaLJ2twag6xCK {
  transform: translateX(-100%); }

._20JrsyiDoBaLJ2twag6xCK.Yu2kBEHFI_Gb5nJ3Kcgdc {
  transition: 3s ease-in-out;
  opacity: 1;
  transform: translateX(0); }

._20JrsyiDoBaLJ2twag6xCK.NBjN7T8MVignDSmNE2k5e {
  -webkit-animation-name: _3jdom40LZeEnfXjdt7Fxjg;
  -webkit-animation-duration: 3s;
  animation-name: _3jdom40LZeEnfXjdt7Fxjg;
  animation-duration: 3s; }

@keyframes _3jdom40LZeEnfXjdt7Fxjg {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(100%); } }
