._16FP_KyjqzXpNmLlC7Kqqx {
  display: none; }

._3RIXzvHfMagbmnUzhX4Ql9 {
  display: flex;
  position: relative; }
  ._3RIXzvHfMagbmnUzhX4Ql9 a {
    text-decoration: none;
    color: #687689; }
    ._3RIXzvHfMagbmnUzhX4Ql9 a:hover {
      text-decoration: none;
      color: #687689; }

._24XHF-2x9nJD9JS3lagkvD {
  width: 100%;
  cursor: pointer; }

@media (max-width: 500px) {
  .pqasPLAApKJb_Be7JN2Oz {
    display: block; } }

div._2re4kSsZ93C_DAT2C1uUv9 {
  text-align: left;
  left: 0; }

div._3gt9y9MmhLCw7mZR42h4V5 {
  text-align: center;
  left: 50%; }
  @media (max-width: 500px) {
    div._3gt9y9MmhLCw7mZR42h4V5 {
      left: 0px; } }

div._1EbYCHDNC-bEcl_Wolcfpm {
  text-align: right;
  right: 0; }

div._2FhPfMbiIVTp5KmXqRv0u3 {
  position: absolute;
  top: 0; }
  div._2FhPfMbiIVTp5KmXqRv0u3._3gt9y9MmhLCw7mZR42h4V5 {
    transform: translate(-50%, 0%); }
    @media (max-width: 500px) {
      div._2FhPfMbiIVTp5KmXqRv0u3._3gt9y9MmhLCw7mZR42h4V5 {
        transform: translate(0%, -50%); } }

div._3seLziqIGiAp44O36alnon {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%); }
  div._3seLziqIGiAp44O36alnon._3gt9y9MmhLCw7mZR42h4V5 {
    transform: translate(-50%, -50%); }
    @media (max-width: 500px) {
      div._3seLziqIGiAp44O36alnon._3gt9y9MmhLCw7mZR42h4V5 {
        transform: translate(0%, -50%); } }

div._2Gplid8Lseyo7iISGhfVhE {
  position: absolute;
  bottom: 0; }
  div._2Gplid8Lseyo7iISGhfVhE._3gt9y9MmhLCw7mZR42h4V5 {
    transform: translate(-50%, 0%); }
    @media (max-width: 500px) {
      div._2Gplid8Lseyo7iISGhfVhE._3gt9y9MmhLCw7mZR42h4V5 {
        transform: translate(0%, -50%); } }

._2j3wEe9ffJ_uAVW37JBa34 {
  font-size: inherit;
  cursor: pointer;
  padding: 10px;
  text-align: center; }

._2_QcyyytLKibvkRExk1k02 {
  padding-bottom: 10px; }

._2oLY9pxgAovIojxrERZuP2 {
  width: 100%;
  max-width: 100%; }

/* Fading animation */
._3iLLxNe7hTngKvzEM4P_nQ {
  position: relative;
  width: 100%; }
  @media (max-width: 500px) {
    ._3iLLxNe7hTngKvzEM4P_nQ div,
    ._3iLLxNe7hTngKvzEM4P_nQ button {
      font-size: inherit; } }

.HNNcjBnhBb_jVU57LbOx3 {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

._2qVNYf2rxonLBtTdwAPYQ4 {
  padding: 15px; }
