._3WsYW5ZahMhZq5uQ0hdWW1 {
  margin: 0 auto; }
  ._3WsYW5ZahMhZq5uQ0hdWW1 h1,
  ._3WsYW5ZahMhZq5uQ0hdWW1 h2,
  ._3WsYW5ZahMhZq5uQ0hdWW1 h3,
  ._3WsYW5ZahMhZq5uQ0hdWW1 h4,
  ._3WsYW5ZahMhZq5uQ0hdWW1 h5,
  ._3WsYW5ZahMhZq5uQ0hdWW1 h6,
  ._3WsYW5ZahMhZq5uQ0hdWW1 p {
    margin-bottom: 15px; }
