._2g9L9qwwXWJAAKWTbtUdP_ {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
  text-align: center;
  overflow: hidden; }
  @media (max-width: 767px) {
    ._2g9L9qwwXWJAAKWTbtUdP_ {
      margin: 0; } }
  @media (min-width: 1200px) {
    ._2g9L9qwwXWJAAKWTbtUdP_ {
      max-width: 1149px;
      min-width: 1149px;
      margin: 0 auto; } }

._1N53rLxtAzywtwsQJnHluh {
  position: relative;
  width: 100%;
  padding: 0 30px 0 20px; }
  @media (max-width: 767px) {
    ._1N53rLxtAzywtwsQJnHluh {
      padding: 0; } }

._2WWVFniFQmdY8LmEIY_JYa {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  transform: translateY(-50%);
  font-size: 32px;
  left: 0;
  z-index: 100; }
  ._2WWVFniFQmdY8LmEIY_JYa:focus {
    outline: 0; }
  ._2WWVFniFQmdY8LmEIY_JYa:hover {
    opacity: 1; }

._3VmBlbzJOKsY3SdETg7XqY {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  font-size: 32px;
  transform: translateY(-50%);
  right: 0;
  z-index: 100; }
  ._3VmBlbzJOKsY3SdETg7XqY:focus {
    outline: 0; }
  ._3VmBlbzJOKsY3SdETg7XqY:hover {
    opacity: 1; }

._2Vu4Ae47BuefBf4Z1Z-axK {
  display: none; }

._3M3A0Az--lGzRFV6gRuqBk {
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-end;
  transition-timing-function: linear; }

._2ndPsNY-WiG7dm8E4kL9-t {
  display: none; }

@media (max-width: 767px) {
  ._2Vu4Ae47BuefBf4Z1Z-axK {
    display: flex;
    margin: 0 4px;
    cursor: pointer; }
    ._2Vu4Ae47BuefBf4Z1Z-axK a {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #111;
      opacity: .2;
      transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1); }
    ._2Vu4Ae47BuefBf4Z1Z-axK a:hover {
      opacity: 1;
      background: #1075b1; }
    ._3GzNjcwY9wCO8akBUQjAhH {
      opacity: .9;
      display: flex;
      margin: 0 4px;
      cursor: pointer; }
      ._3GzNjcwY9wCO8akBUQjAhH a {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
        opacity: 1;
        background: #01A3E5; }
  ._2ndPsNY-WiG7dm8E4kL9-t {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin: 20px 0;
    padding: 0; }
  ._3VmBlbzJOKsY3SdETg7XqY, ._2WWVFniFQmdY8LmEIY_JYa {
    display: none; } }

._3XHdOnTVdaoBuP2xCK9UJB {
  text-align: left; }

._2xHXPOTrZuwj05JGMgbGm6 {
  text-align: center; }

.Hrvm1l_ML-vwt8dQ-LTOK {
  text-align: right; }

._1KkbP5i28ntLhgAePPRpsL {
  margin-top: 15px; }

._2mlSHlCcJqfF9Iu_FbbCZB {
  margin-top: 15px; }
  ._2mlSHlCcJqfF9Iu_FbbCZB ._23XR9HYHwNMWtyGD0m0ShU {
    line-height: 24px;
    color: black;
    margin: 0; }

._19UdWXJOcwRN-fIiUGYJLv {
  cursor: pointer; }

/* Hide the images by default */
._1RwK9dyDZYSWXb5rwaFvFt {
  display: none; }
  @media (max-width: 767px) {
    ._1RwK9dyDZYSWXb5rwaFvFt {
      display: block; } }

._3i8-y_NoMymAJ_OJFCIH3N {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px; }

.f2q99D-pmwkyEDhSbjJ6p {
  height: 1px;
  border-bottom: 0.5px solid #0074B3;
  width: 150px;
  margin: auto;
  margin-bottom: 20px; }

.xjZunNIWzgr5xquQsHMeO {
  position: relative; }

.TrY3ViZNDhL5xE8IGG9Vl {
  margin-top: 25px;
  height: 200px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 767px) {
    .TrY3ViZNDhL5xE8IGG9Vl {
      height: 400px;
      max-width: 400px;
      display: block;
      margin: 0 auto; } }

._2HJv8ZpkwsAl3dVjkDcKiz {
  display: flex;
  align-content: stretch; }
  ._2HJv8ZpkwsAl3dVjkDcKiz a {
    text-decoration: none;
    color: #687689; }
    ._2HJv8ZpkwsAl3dVjkDcKiz a:hover {
      text-decoration: none;
      color: #687689; }
  ._2HJv8ZpkwsAl3dVjkDcKiz ._1w1z8NVe4Yzk0XtqM5XUOE {
    padding: 0.7rem;
    text-align: left; }
  ._2HJv8ZpkwsAl3dVjkDcKiz ._1yqqiXGXgvWJwNggkhI5YA {
    min-width: calc(23vw - 50px) !important;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
    transition: transform ease-in-out 0.25s; }
    @media (min-width: 1200px) {
      ._2HJv8ZpkwsAl3dVjkDcKiz ._1yqqiXGXgvWJwNggkhI5YA {
        width: 248px !important;
        min-width: 248px !important; } }
    @media (max-width: 767px) {
      ._2HJv8ZpkwsAl3dVjkDcKiz ._1yqqiXGXgvWJwNggkhI5YA {
        min-width: 100% !important;
        margin: 0; }
        ._2HJv8ZpkwsAl3dVjkDcKiz ._1yqqiXGXgvWJwNggkhI5YA img {
          width: 100%; } }
    @media (max-width: 500px) {
      ._2HJv8ZpkwsAl3dVjkDcKiz ._1yqqiXGXgvWJwNggkhI5YA {
        padding: 0 2px 0 2px; } }

._1yqqiXGXgvWJwNggkhI5YA {
  width: calc(23vw - 30px);
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
  transition: transform 1s; }
  @media (min-width: 1200px) {
    ._1yqqiXGXgvWJwNggkhI5YA {
      width: 266px !important; } }
  @media (max-width: 767px) {
    ._1yqqiXGXgvWJwNggkhI5YA {
      min-width: calc(100vw - 30px);
      display: inline-block;
      width: 100%;
      padding: 0 15px 0 0; }
      ._1yqqiXGXgvWJwNggkhI5YA img {
        width: 100%; } }
  @media (max-width: 500px) {
    ._1yqqiXGXgvWJwNggkhI5YA {
      padding: 0 2px 0 2px; }
      ._1yqqiXGXgvWJwNggkhI5YA:last-of-type {
        padding: 0 0 0 2px; } }

._3IW3O-K5mq6DxPs2tOr40z {
  width: 100%;
  height: 200px; }

div.eHqIKgEL9oOY06v0A6AWX {
  text-align: left; }

div._2_mkguHi4ynGUKVCT0UUxQ {
  text-align: center; }

div._1beiQgRRvFKmO4mAH06Gnr {
  text-align: right; }

._1lVSUZC-ALE8oIKrTURTVw {
  background: #065784;
  color: #fff !important;
  font-family: Lato, sans-serif;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc;
  height: 44px; }
  ._1lVSUZC-ALE8oIKrTURTVw:hover {
    background: #1084b5; }

._2iamsXOYZOdktkc2yAbVrU {
  font-weight: bolder;
  font-size: 1rem;
  margin: 0 5px; }

._2VS9ZgsMGLQW1mzBhLC7Gr {
  color: #aaa;
  font-weight: bold; }
  ._2VS9ZgsMGLQW1mzBhLC7Gr ._3f-x78lyr_TY4w6UXhap0V {
    color: #aaa;
    text-decoration: line-through;
    margin: 0 5px; }
    ._2VS9ZgsMGLQW1mzBhLC7Gr ._3f-x78lyr_TY4w6UXhap0V span {
      color: #aaa; }

._1x8A14E1Ns5NJ5_ZcPIVWo {
  font-weight: bold; }

._2Sn7rb7DTeomvG8SgeG_LZ {
  margin: 15px 0;
  font-weight: bold;
  text-align: center; }

._2Kvl1Jx-lXLw22Vh2wRotu {
  display: none; }

._cifIXU6-KmgQSqwT2W2m {
  width: 50%;
  padding: 12px; }

._2mseKNdmvWei3PbqnqS2BA {
  text-align: center;
  margin-top: 12px;
  margin-right: 10px;
  height: 50px; }
  @media (max-width: 767px) {
    ._2mseKNdmvWei3PbqnqS2BA {
      width: 50%;
      min-width: 240px;
      margin: 10px auto 0; } }
