._25sqhk3usHHblL8R7iNWqN,
.CzuDbibqWAOHE7I3xu5VG {
  position: relative; }

._2Ts5ZOCVd8XEId7pNglExI {
  color: white !important; }

._25f2EXij7xqt0FUwWEpNV_ {
  font-size: 1.25rem;
  color: white !important; }

._2k9F7DiShyxRQjY-gJXV19 {
  max-width: 1170px;
  padding: 15px;
  margin: 0 auto; }

._25sqhk3usHHblL8R7iNWqN:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

.CzuDbibqWAOHE7I3xu5VG:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._1BIHwVD1rZd-yCfE2YIWzb {
  background-attachment: fixed !important;
  background-size: contain; }
  ._1BIHwVD1rZd-yCfE2YIWzb .GXNV_NI1-JGbKk2C6LUze {
    position: relative;
    z-index: 1;
    color: white; }
