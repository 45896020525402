._3xrNLD-p0GEXA1e_lmAJxl {
  margin-bottom: 5px; }
  ._3xrNLD-p0GEXA1e_lmAJxl i {
    font-size: 1.125rem;
    margin-left: 5px; }

.cgxSkqAdJ_w43A-UaB2Q_ {
  display: flex;
  height: max-content;
  flex-grow: 1; }

._2zQ-J-aJ47htKNWFGeNFH0 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  width: 100%; }

@media screen and (max-width: 768px) {
  .cgxSkqAdJ_w43A-UaB2Q_ {
    width: 100% !important; } }

@media screen and (min-width: 768px) {
  .jpdo6ZWWFJxzQQMAQP7yH {
    width: 100%; }
  ._2Peb9GenZhF2DicwWJ7BYP {
    width: 91.66666667%; }
  ._1NCB5oLvmjQb4OSSuU2k5t {
    width: 83.33333333%; }
  ._27JSYJVhcGWIl1eCwKBJyX {
    width: 75%; }
  ._39J_haB3H1d97UV4zrEtWJ {
    width: 66.66666667%; }
  ._1p3yheh5O1FOUVIHqWuavm {
    width: 58.33333333%; }
  ._2AIhbRW16Q6yR5InxIej0V {
    width: 50%; }
  ._22BEQjDLWaLF55B1hthv2f {
    width: 41.66666667%; }
  ._2fF1mvVuAbfdGK2UFEAgXR {
    width: 33.33333333%; }
  ._2I1GGvdNZFmhdYGt25wGN {
    width: 25%; }
  ._1Dp0hXimTAJRoTe0tKeW5X {
    width: 16.66666667%; }
  ._6GN0-3SPpgF6wTIJZ9bu {
    width: 8.33333333%; } }
