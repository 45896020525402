._1BtavjW9xjewCp5ZNOGe5d {
  width: 100%; }

._3SIDgcrqr2TvCQ2REuXMiS {
  margin-top: 15px; }

._32_NTw_7MKSI2K8JqbhLDA {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }
