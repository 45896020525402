._3Ja8qrnr9rRqSxXKUZkClW a {
  text-decoration: none; }

._3Ja8qrnr9rRqSxXKUZkClW .OCh6JQVZ-d4C-0b3LS_Xy {
  -webkit-appearance: none;
  background-color: white;
  color: black;
  outline: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-align: center; }

._3rjYtm4-8B1ry2Q0MoJUFq {
  display: inline-block; }
  ._3rjYtm4-8B1ry2Q0MoJUFq .OCh6JQVZ-d4C-0b3LS_Xy {
    background: none;
    padding: 0; }
  ._3rjYtm4-8B1ry2Q0MoJUFq * {
    color: #1075B1; }
