._3fdQ5QL1vhaEqquLFF_Xc6 * {
  text-align: right; }

.xTUYstsyYnY0FjQMV2nWi * {
  text-align: center; }

.NzlWNSawRNFs07QW1AZVj * {
  text-align: left; }

._2wAKUJMycRaafCUOEWoddh * {
  text-align: right; }

._32-zcuNiYDHs4pxiYkFND4 * {
  text-align: center; }

._2cQ12aYDUBdD1o0W4imBqv * {
  text-align: left; }

.z0Y0b5tUQGjyaF63X3rQn * {
  text-align: right; }

._3T_0F1bDM44DI9CnOsxA6R * {
  text-align: center; }

._15e1Reo9sLrszx5TEJYgfS * {
  text-align: left; }

._1Ac4zanpJeKWyCW9hOnqZ6 * {
  text-align: right; }

._1K-lSTEf2rmStfkBeeNWX4 * {
  text-align: center; }

._1q1eFNVV8RcQbrW89BXQiv * {
  text-align: left; }
