.ji520eb9ovanu2v8FEvI8 {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px; }

._2z8BOQDFgkSlc7SQOPd20x {
  position: relative;
  border: 1px solid #eee;
  cursor: pointer;
  margin-bottom: 30px; }

.oAjIytMxPIv1qr0LnQ21J {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

._qY7M0NQiBGoPqoxo3d0K {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  transition: .5s all;
  opacity: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._qY7M0NQiBGoPqoxo3d0K:hover {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
  ._qY7M0NQiBGoPqoxo3d0K i {
    font-size: 2.1875rem; }

.dHrKhHNa-pAL2Hl0w7BkC {
  width: 100%;
  background: white; }

._31uyO_Zhd-tuDlSZgm6sCL {
  position: absolute;
  width: 70%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  padding: 15px; }

._2V4L38_W66pPHOR73Q1cji {
  color: white !important; }
