._2uZGsJeT1iDwmXihEfQzHF,
._1huYSg82-w9z1l0dpjghHM {
  position: relative; }

._1L78GCeGJ3X4R8_ebw1VMP {
  margin-top: 10px; }

._1utI-2tsX__RL5VFWIkRP9 {
  max-width: 1170px;
  padding: 15px;
  margin: 0 auto; }

._2uZGsJeT1iDwmXihEfQzHF:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

._1huYSg82-w9z1l0dpjghHM:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._2Z2lDYNALY9C8nLadGsCWL {
  background-attachment: fixed !important;
  background-size: contain; }
  ._2Z2lDYNALY9C8nLadGsCWL .Un2KuvrpyiitwJErdBFLI {
    position: relative;
    z-index: 1;
    color: white; }
