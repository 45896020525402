._2HHozdG7-Lt4Ojvq_K1Lrw {
  position: relative;
  border: 1px solid #eee;
  cursor: pointer; }

._3O4UhmWqSNqvT4C0zWJV5v {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

._2--oThfNJLwRvZ8yN6kDjU {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  transition: .5s all;
  opacity: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._2--oThfNJLwRvZ8yN6kDjU:hover {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
  ._2--oThfNJLwRvZ8yN6kDjU i {
    font-size: 2.1875rem; }

.TzuPWAMAekWyh6f24K4s_ {
  width: 100%;
  background: white; }

._1QMcjwy_zSeN5ikyfsHGrc {
  position: absolute;
  width: 70%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  padding: 15px; }

._3X5xfGhZsva_-S9uJPpOVt {
  color: white !important; }
