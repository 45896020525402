._2kXbTw0VaJ46Yus8lku8JC {
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px;
  height: 100px;
  cursor: pointer; }
  ._2kXbTw0VaJ46Yus8lku8JC:first-of-type {
    margin-top: 0px; }
  ._2kXbTw0VaJ46Yus8lku8JC:last-of-type {
    margin-bottom: 0px; }

._2O_QiiGWV4HjRw9OUozV7G {
  margin: 0 5px; }

._321w8uAmknfdYzMSibgV1k {
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }

._2kXbTw0VaJ46Yus8lku8JC img {
  align-self: center; }

._3hE1gbpCdL972K7so-XbWT {
  opacity: 0.6;
  transition: 0.5s opacity; }

._3hE1gbpCdL972K7so-XbWT:hover {
  opacity: 1; }

._3btejNR3ygaPDQVATNkmwF {
  object-fit: cover; }
  @media (max-width: 991px) {
    ._3btejNR3ygaPDQVATNkmwF {
      max-height: 200px; } }
  @media (min-width: 991px) {
    ._3btejNR3ygaPDQVATNkmwF {
      max-width: 100%;
      max-height: 30em; } }

._3ipYu07DKLb6uHXkdIBMfY {
  display: flex; }

._2vt85juX7q2lTMgJe4FO-s {
  width: 100%;
  display: flex; }

._1ln0TkP9vWveQVx3u6p7Ph {
  width: 100%;
  display: flex;
  flex-direction: column-reverse; }

._3ddpgyuNvmJtD71J--iwPB i {
  align-self: center;
  width: 100%; }

._3ddpgyuNvmJtD71J--iwPB > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; }

._37dpEbwwbMhFgeodpukUEe > div {
  flex-direction: row; }

._2C503Fh4ew9BAGrVqOc7r9 {
  flex: 4;
  align-content: center;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex; }
  @media (max-width: 768px) {
    ._2C503Fh4ew9BAGrVqOc7r9 {
      align-items: center; } }
  ._2C503Fh4ew9BAGrVqOc7r9 .Dpp5QzHofhg8BpOIXgbRj {
    margin: 0; }
    @media (max-width: 991px) {
      ._2C503Fh4ew9BAGrVqOc7r9 .Dpp5QzHofhg8BpOIXgbRj {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  @media (max-width: 991px) {
    ._2C503Fh4ew9BAGrVqOc7r9 ._329ZWLzE4Pz7uClKThlte1 {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px; } }

._1CzhNPGe9ivdFni2LdPSAR {
  justify-content: flex-start !important;
  flex: auto !important; }
  ._1CzhNPGe9ivdFni2LdPSAR ._3btejNR3ygaPDQVATNkmwF {
    max-width: 100%; }

.AeLa2mg_OPoOHXY_6Xi0c:hover {
  color: gray; }

.AeLa2mg_OPoOHXY_6Xi0c {
  font-size: 1.875rem;
  color: darkgray; }

.SXwbTajLLILOLjaDHi8Zi {
  cursor: pointer; }

@media (max-width: 991px) {
  ._2pv_3Za7Be-ziJpc_9epEx {
    margin-left: 0px !important; } }

@media (max-width: 991px) {
  ._3HOPMZXKePs-a7OXt86hQt {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

._1hRBKm2tJM6SrrRtmbFj1:before {
  content: "\F144";
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 100px; }

._2Ws43HqmMGdNctfQ-ig4yO {
  opacity: 1;
  transition: opacity 0.5s linear; }

@keyframes _2YopylKS4ZTzYwmJv19SLo {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._3MQZe5zjNTVX4yVlD6OYg5 {
  display: none; }

._17qK_rO5vbDVVCnuWXeKdX {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 35px;
  width: auto !important; }

.ZptHp9z-5ndHtiahovIdn {
  position: relative;
  height: 100%; }
