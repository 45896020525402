.jXR2GG-BY3ZK1Sa4LBjT_ {
  display: flex;
  flex-flow: row wrap; }
  .jXR2GG-BY3ZK1Sa4LBjT_.wcrNRPn-Hc1k6OkeJz1rs ._3jlgwYsSUybVhucM5BsK_I {
    min-width: 100%; }
  .jXR2GG-BY3ZK1Sa4LBjT_._3ltFMCwHpKXTToyHarZgFw ._3jlgwYsSUybVhucM5BsK_I {
    min-width: 50%; }

._1qTXBdIe4Z11bn6KoO3oKk {
  flex: 1; }
  ._1qTXBdIe4Z11bn6KoO3oKk .yuf70lPBzk-FAJEenJe8D {
    text-align: left; }
  ._1qTXBdIe4Z11bn6KoO3oKk .uQNguJ3ljUKIzW6vWTRr9 {
    text-align: center; }
  ._1qTXBdIe4Z11bn6KoO3oKk ._1_sGi0_lam6qPahTL4YRiN {
    text-align: right; }

._3F_3xvyD0RMvbYELA5Cg45 {
  width: 100%;
  cursor: pointer; }

._3mQ5ySI-Q-nzcHa3sLxaq0 {
  margin-top: 15px; }

._1F6N4AvifY2SzXIGktZ_79 {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1; }

/* Hide the images by default */
._3v7klwx5jzRJj6lW6VtIjv {
  display: flex;
  position: relative; }
  ._3v7klwx5jzRJj6lW6VtIjv a {
    text-decoration: none;
    color: #687689; }
    ._3v7klwx5jzRJj6lW6VtIjv a:hover {
      text-decoration: none;
      color: #687689; }

/* The dots/bullets/indicators */
._1yxHgnAVNST-rGn0COQZna {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._2IR6McjaxC3dl28OgtCscU,
._1yxHgnAVNST-rGn0COQZna:hover {
  background-color: #717171; }

/* Next & previous buttons */
.sAuhbn3iJUgpGYlImyt3F,
._32-RbFQtqN-WtqNd0ninDc {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: 0.3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._32-RbFQtqN-WtqNd0ninDc {
  right: 0;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
a.sAuhbn3iJUgpGYlImyt3F:hover,
a._32-RbFQtqN-WtqNd0ninDc:hover {
  color: #fff;
  text-decoration: none; }

._2Zb4iqAn46RefJfz3oRLWB {
  padding-bottom: 10px; }

@media (min-width: 768px) {
  .fAxIc67hmGOcZjYh8SQKN {
    padding: 0 2px 0 2px; }
    .fAxIc67hmGOcZjYh8SQKN:first-of-type {
      padding: 0 2px 0 0; }
    .fAxIc67hmGOcZjYh8SQKN:last-of-type {
      padding: 0 0 0 2px; } }

.VBvYc5MJJN6cMjesYE-bh {
  transition: box-shadow 0.2s ease-out; }
  .VBvYc5MJJN6cMjesYE-bh:hover {
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1); }

.bazYFCFguZUdwd48XeYmL {
  max-width: 100%;
  max-height: 300px; }

/* Fading animation */
._1E_YuKTWD-ZNnTC2L_NR3f {
  -webkit-animation-name: _1E_YuKTWD-ZNnTC2L_NR3f;
  -webkit-animation-duration: 1.5s;
  animation-name: _1E_YuKTWD-ZNnTC2L_NR3f;
  animation-duration: 1.5s; }

._3ZAIk3gIXhqghr7sLqJswA {
  display: none;
  text-align: center;
  margin-top: 15px; }

._16GqoFs3ndFwhWEDInvfNK {
  display: none; }

._1L-8JmimKSGid0lS9tqj1f {
  display: block; }

._3fGsWC_yPpxt9WejEsL_O6,
._13EVezqvPt8o5mId9kWNEQ {
  display: block; }

@media (max-width: 768px) {
  .VBvYc5MJJN6cMjesYE-bh:hover {
    transition: unset; }
  ._1qTXBdIe4Z11bn6KoO3oKk {
    min-width: 100% !important;
    transition: transform ease-in-out 0.25s; }
  ._1F6N4AvifY2SzXIGktZ_79 {
    width: 100%;
    display: inline-flex;
    justify-content: unset;
    overflow: hidden; }
  .wcrNRPn-Hc1k6OkeJz1rs {
    display: block; }
  ._5DIDOq90XVSTc8ZmeG0PL {
    display: block; }
  ._3ltFMCwHpKXTToyHarZgFw {
    display: flex;
    flex-flow: row wrap;
    min-width: 50% !important;
    padding: 8px !important;
    margin: 0 !important; }
  .Xrs_CxVyQNKMjQhQZYLZ4 {
    min-width: 50% !important;
    padding: 8px !important;
    margin: 0 !important; }
  ._3fGsWC_yPpxt9WejEsL_O6,
  ._13EVezqvPt8o5mId9kWNEQ {
    display: none; }
  ._2W7SeZ1tAP6oJSlfdNZZ15,
  ._3B8uqvtRMJ1Gyi3QY2_TGR {
    display: block; }
  ._16GqoFs3ndFwhWEDInvfNK {
    display: block; } }

@-webkit-keyframes _1E_YuKTWD-ZNnTC2L_NR3f {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes _1E_YuKTWD-ZNnTC2L_NR3f {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

._16nIUKn25t9q2SR3QyDv2W {
  background-repeat: no-repeat; }
