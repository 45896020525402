._2TRhBIJ36TiZj8PoAFwE7u {
  display: none; }

._1n-wL3etCnN-7q0AdXhUNr {
  display: flex;
  position: relative; }
  ._1n-wL3etCnN-7q0AdXhUNr a {
    text-decoration: none;
    color: #687689; }
    ._1n-wL3etCnN-7q0AdXhUNr a:hover {
      text-decoration: none;
      color: #687689; }

._3B2PWuoW3rPVhh1-lgC1Eq {
  width: 100%;
  cursor: pointer; }

@media (max-width: 500px) {
  ._3MN40RSrRoVtfhOk40dTU8 {
    display: block; } }

div._1kv9xjgrJ2-FOuQg54-Fcp {
  text-align: left;
  left: 0; }

div._1ro0ZZa3Eajx21-OfvwyOK {
  text-align: center;
  left: 50%; }
  div._1ro0ZZa3Eajx21-OfvwyOK._1BqQpZI0hLHxuyHAbTuhcA {
    left: 0px; }
  @media (max-width: 500px) {
    div._1ro0ZZa3Eajx21-OfvwyOK {
      left: 0px; } }

div._2OwFglGtI9M3dHtL6vMIIu {
  text-align: right;
  right: 0; }

div._3LhNgajoPfyFU-1FG1THm2 {
  position: absolute;
  top: 0; }
  div._3LhNgajoPfyFU-1FG1THm2._1ro0ZZa3Eajx21-OfvwyOK {
    transform: translate(-50%, 0%); }
    div._3LhNgajoPfyFU-1FG1THm2._1ro0ZZa3Eajx21-OfvwyOK._1BqQpZI0hLHxuyHAbTuhcA {
      transform: translate(0%, -50%); }
    @media (max-width: 500px) {
      div._3LhNgajoPfyFU-1FG1THm2._1ro0ZZa3Eajx21-OfvwyOK {
        transform: translate(0%, -50%); } }

div._3IblkFsuoNYL5ZK4I4XXkq {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 100%; }
  div._3IblkFsuoNYL5ZK4I4XXkq._1ro0ZZa3Eajx21-OfvwyOK {
    transform: translate(-50%, -50%); }
    div._3IblkFsuoNYL5ZK4I4XXkq._1ro0ZZa3Eajx21-OfvwyOK._1BqQpZI0hLHxuyHAbTuhcA {
      transform: translate(0%, -50%); }
    @media (max-width: 500px) {
      div._3IblkFsuoNYL5ZK4I4XXkq._1ro0ZZa3Eajx21-OfvwyOK {
        transform: translate(0%, -50%); } }

div._3H8tVnbuiz-aLfI2VMO3Zv {
  position: absolute;
  bottom: 0; }
  div._3H8tVnbuiz-aLfI2VMO3Zv._1ro0ZZa3Eajx21-OfvwyOK {
    transform: translate(-50%, 0%); }
    div._3H8tVnbuiz-aLfI2VMO3Zv._1ro0ZZa3Eajx21-OfvwyOK._1BqQpZI0hLHxuyHAbTuhcA {
      transform: translate(0%, -50%); }
    @media (max-width: 500px) {
      div._3H8tVnbuiz-aLfI2VMO3Zv._1ro0ZZa3Eajx21-OfvwyOK {
        transform: translate(0%, -50%); } }

._3NUun3_W2Udkjs9CVoVoE7 {
  font-size: inherit;
  cursor: pointer;
  padding: 10px;
  text-align: center; }

._2nZQqmhpHeTMMr0B5YMPPS {
  padding-bottom: 10px; }

._3kJM1ZubvKDQl6MTdW2n_A {
  width: 100%;
  max-width: 100%; }

/* Fading animation */
._3Mf41XkKU8SvAcOBd3ovep {
  position: relative;
  width: 100%; }
  @media (max-width: 500px) {
    ._3Mf41XkKU8SvAcOBd3ovep div,
    ._3Mf41XkKU8SvAcOBd3ovep button {
      font-size: inherit; } }

.teONsgPJJyG7HzXmaYQ4a {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

._16SeNx64eolmzAz-ftTDfU {
  padding: 15px; }
