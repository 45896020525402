._2V6QJCq66iBbqhay6qO4e_ {
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase; }

._3kjOZe5Et3bLYNN6MS-DYt {
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px; }

.jdbVNGPHjDVDIF2u6M9XG {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }
