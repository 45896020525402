.Cbr_MGSrHafMZWny_Hi4N {
  line-height: 22px;
  margin-top: 15px; }

._3UqE7yWqq7HwLCs5d5A5C3 {
  color: #D0021B !important;
  font-size: 16px;
  font-weight: bold; }
  ._3UqE7yWqq7HwLCs5d5A5C3 ._2p665QV4BZO8lX9iX79lfi {
    color: #D0021B;
    font-size: 22px; }

._3tVUviqimYtun3BR2p_kSn {
  font-size: 16px;
  font-weight: bold; }

._2p665QV4BZO8lX9iX79lfi {
  font-size: 22px; }

.UpZY4OPw0SQKWmOJFzXe6 {
  color: #8C8B8B !important;
  font-size: 16px;
  font-weight: 600; }

._2nDnSgd99CSQgL5HYN6j6A {
  text-decoration: line-through;
  color: #8C8B8B !important;
  font-size: 16px; }
