.qcHIFSZeFPP1SFMU8F2Ak {
  position: relative; }

.qcHIFSZeFPP1SFMU8F2Ak:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65); }

.T3L768D_lHn13JAVEoJNk {
  background-attachment: fixed !important;
  background-size: cover;
  background-repeat: no-repeat; }
  .T3L768D_lHn13JAVEoJNk ._1T4EiXvcWUE2oW4U_m5FeA {
    position: relative;
    z-index: 1;
    color: white; }

._3vGvdLXZl2GhQ5HuBGIzXU * {
  word-wrap: break-word; }
