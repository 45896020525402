._3neEplPbVSNJzugdLXL6m0 {
  margin-top: 20px; }
  ._3neEplPbVSNJzugdLXL6m0 .WEU7e5A4uv4yYIxDeDhUJ {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto auto;
    margin-bottom: 5px;
    padding-bottom: 5px; }
  ._3neEplPbVSNJzugdLXL6m0 ._38gVBNjk_UZUl9g7AkPllE {
    display: flex;
    justify-content: left;
    color: red;
    font-size: 0.78rem; }
  ._3neEplPbVSNJzugdLXL6m0 ._2mKpnshsbSB8eG1vgL_5F7 {
    display: flex; }
    ._3neEplPbVSNJzugdLXL6m0 ._2mKpnshsbSB8eG1vgL_5F7 span {
      margin: auto; }
    ._3neEplPbVSNJzugdLXL6m0 ._2mKpnshsbSB8eG1vgL_5F7 input {
      width: 40%;
      margin: 1%;
      background-color: #f4f4f4; }
  ._3neEplPbVSNJzugdLXL6m0 div {
    display: flex; }
  ._3neEplPbVSNJzugdLXL6m0 ._3oZS0cqgZl6-PL0hwj5SyY {
    height: 82%;
    margin: auto auto; }
  ._3neEplPbVSNJzugdLXL6m0 ._28fi3VJAjE2N32qwVdPfsR {
    border: 1px solid red; }
  ._3neEplPbVSNJzugdLXL6m0 ._28fi3VJAjE2N32qwVdPfsR:focus {
    border: 1px solid red;
    outline: red; }
