.oL0FGuYao4b4a_8jvUeo1 {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 4rem 0 8rem;
  min-height: 500px; }

._6OZgBzjRX5ulWMoY_KtvB {
  padding: 0 1.5rem;
  text-align: center;
  padding: 0 50px;
  margin-bottom: 50px;
  width: 100%;
  position: relative; }

._14mc35GWVbojPTH-EJJ7pe {
  font-style: italic;
  padding: 0 1.5rem;
  text-align: center; }

.wpk9rws8QlPPJFRpDUubH {
  height: 8rem;
  position: absolute;
  bottom: 0; }

.rvbKy-JlLjLX3dy_EUql_ {
  position: absolute;
  color: #b4b4b4;
  font-size: 4rem; }
  .rvbKy-JlLjLX3dy_EUql_.fa-quote-left {
    left: 0;
    top: -4rem; }
  .rvbKy-JlLjLX3dy_EUql_.fa-quote-right {
    right: 0;
    bottom: -4rem; }

@media only screen and (max-width: 767px) {
  .oL0FGuYao4b4a_8jvUeo1 {
    min-height: 300px; }
  .wpk9rws8QlPPJFRpDUubH {
    height: 5rem; }
  .rvbKy-JlLjLX3dy_EUql_.fa-quote-left {
    left: -20px; }
  .rvbKy-JlLjLX3dy_EUql_.fa-quote-right {
    right: -20px;
    bottom: -3rem; } }
