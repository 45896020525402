._3zF9qnpcfygidD1Vq65NY2 {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px; }

._225wey99X98yr3FSlIKrSN {
  position: relative;
  border: 1px solid #eee;
  cursor: pointer;
  margin-bottom: 30px; }

._2a4i3J7kAo2jmqiOKzXrFT {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

._1F--EE29_fAD6ncwAozLJ {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  transition: .5s all;
  opacity: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._1F--EE29_fAD6ncwAozLJ:hover {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
  ._1F--EE29_fAD6ncwAozLJ i {
    font-size: 2.1875rem; }

._1bBKohhelHWGekPEgSivnL {
  width: 100%;
  background: white; }

.AXi0eDS7VY38adoXG3VAp {
  position: absolute;
  width: 70%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  padding: 15px; }

._2jCxMlDVjiLZFoYsWStGpT {
  color: white !important; }
