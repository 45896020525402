._3ATv1xu99fuByVHO2lawop {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center; }

.-bFwvW9sT_ltGGNAmxvQ- > * {
  margin-left: 20px;
  margin-right: 20px; }

/* The dots/bullets/indicators */
._3aoNYo9Dwa3sFRuoWHxNQo {
  text-align: center;
  margin-top: 15px; }

.vPamKKijdfXOvrrWEKFCe {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._1vCLCLhHokiS_eZEPp0EVg,
.vPamKKijdfXOvrrWEKFCe:hover {
  background-color: #717171; }

/* Next & previous buttons */
.SYiigalyoiechWe4TTzkZ,
._1S8dVeoKpXEpsDa4MC_HBl {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none;
  background: none;
  border: none;
  padding: 0 !important;
  transition: 0.3s all ease; }

.SYiigalyoiechWe4TTzkZ {
  left: 10px; }

._1S8dVeoKpXEpsDa4MC_HBl {
  right: 10px; }

._1fEa9vzhB6RKCgNIv8Pyc3 {
  width: 100%;
  max-width: 100%; }

._2RMwbFEFnssBTmf45CNNHt {
  text-align: center; }

._202eJnQRCjOm5A3Y4xpREA,
._1top0fENtULHhPLxiQrBGU {
  cursor: pointer;
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  background: none;
  border: none;
  padding: 0 !important; }
