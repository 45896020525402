._6ELs5_IP1vyKZMHfcdCgq {
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  flex: 1 0 33.33%;
  min-width: 260px; }

.MQWHQ8BiUvzQbwl0KNZcJ {
  margin: 10px;
  cursor: pointer; }
  .MQWHQ8BiUvzQbwl0KNZcJ img {
    width: 100%;
    height: auto; }

._2rPoGfPKL7Sd_pw8Jg0h4R {
  color: black;
  font-weight: bold; }

._2n8yudcElsIEMW0lveR28k {
  line-height: 22px;
  font-weight: 700;
  min-height: 4rem; }
  ._2n8yudcElsIEMW0lveR28k div {
    color: #3D3D3D;
    font-weight: 700; }

._3EeAHxNU7uxN2cYlX4Xasu {
  color: #3D3D3D; }

._1VrUt5wKLWVRdlhqlfoi4t {
  color: #8c8b8b;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 17px; }

.xLkhor3IxaR61cco3AQ_0 {
  padding: 20px; }

._61eJCqgW-eFXmW27N6nnt {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

._3c5tJCs9jAU9jMYpCK54MD {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

.T1_78S2620mNl-WOIZDxe {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  padding: 0 20px; }
