.cU-I1Pv_wOH062doy15SJ ._1yFIF6yPrsyPPxLMtPHR5N {
  margin: auto; }

.cU-I1Pv_wOH062doy15SJ .zNV7T5p7pQlg7qFZ68xpv {
  margin-top: 15px; }

.cU-I1Pv_wOH062doy15SJ ._3g8ttEuEcV8TT_RfPIDyN7 ._3SSS_Xe_QmR2T0D47VcBxd:not(:first-child) {
  margin-top: 20px; }

.cU-I1Pv_wOH062doy15SJ ._1rw8cklqOD2Iq2Aoisb868,
.cU-I1Pv_wOH062doy15SJ ._2gcA4klWVoChHs88LDuGyi,
.cU-I1Pv_wOH062doy15SJ .lD9u0Iy-tTj7CgKnviOHB {
  display: none; }

.cU-I1Pv_wOH062doy15SJ .gCj1PvOq1CYe2LJa0TprX {
  display: none; }

.cU-I1Pv_wOH062doy15SJ ._3g8ttEuEcV8TT_RfPIDyN7,
.cU-I1Pv_wOH062doy15SJ ._1HT1RGcmVVA7ydsrvNk0mV {
  margin-left: 15px;
  margin-bottom: 15px; }

.cU-I1Pv_wOH062doy15SJ ._2AASWYm4Zvv1YOeJ-9Tx6z {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.cU-I1Pv_wOH062doy15SJ ._2a_UuqXcN81pFXpPE1XTr_ {
  margin-left: 15px;
  line-height: 28px; }
  .cU-I1Pv_wOH062doy15SJ ._2a_UuqXcN81pFXpPE1XTr_ a {
    cursor: pointer;
    color: black;
    text-decoration: none;
    word-wrap: break-word; }
    .cU-I1Pv_wOH062doy15SJ ._2a_UuqXcN81pFXpPE1XTr_ a:hover {
      color: #0087b0;
      text-decoration: none; }

.cU-I1Pv_wOH062doy15SJ ._2f7xE_oyjk3Gx75g1Ittex {
  margin-left: 0;
  line-height: 28px; }
  .cU-I1Pv_wOH062doy15SJ ._2f7xE_oyjk3Gx75g1Ittex a {
    cursor: pointer;
    color: black;
    text-decoration: none;
    word-wrap: break-word; }
    .cU-I1Pv_wOH062doy15SJ ._2f7xE_oyjk3Gx75g1Ittex a:hover {
      color: #0087b0;
      text-decoration: none; }

.cU-I1Pv_wOH062doy15SJ ._2MWNlQQaWLXNsNVWOQhJcX {
  display: block; }

.cU-I1Pv_wOH062doy15SJ .XwnpQwEH2AaJ33wMyG97h {
  display: none; }

.cU-I1Pv_wOH062doy15SJ ._3wxgVGP4jOsK6_HJk2oQi5 {
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  margin-left: 15px;
  color: #4899c5;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem; }

.cU-I1Pv_wOH062doy15SJ ._2AQBao1Z61MOTTvbonFezg {
  margin-left: 5px; }

.cU-I1Pv_wOH062doy15SJ ._24i6DpK0c-Wi3pFQzoNKLy {
  margin-top: 15px; }
  .cU-I1Pv_wOH062doy15SJ ._24i6DpK0c-Wi3pFQzoNKLy a {
    color: #4899c5;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: bold; }

.cU-I1Pv_wOH062doy15SJ ._2JvD7Eod6vbcKwCBnJe08s {
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 5px; }

.cU-I1Pv_wOH062doy15SJ ._1GPEicjoK8S3olV7p7-QfO {
  cursor: pointer;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-50%); }

@media (max-width: 767px) {
  .cU-I1Pv_wOH062doy15SJ .gCj1PvOq1CYe2LJa0TprX {
    position: absolute;
    right: 15px;
    cursor: pointer;
    display: none; }
  .cU-I1Pv_wOH062doy15SJ .giPj8gL8jSv9qgJAepKlY,
  .cU-I1Pv_wOH062doy15SJ ._34AA0L_faT4cxfpKQ9xtjO {
    display: inline; }
  .cU-I1Pv_wOH062doy15SJ ._2f7xE_oyjk3Gx75g1Ittex {
    display: none; }
  .cU-I1Pv_wOH062doy15SJ ._2j67Yew795ojT5C-a8aJao,
  .cU-I1Pv_wOH062doy15SJ ._3S-_-yuRE5k7YE1x9T9h0X {
    display: inline; }
  .cU-I1Pv_wOH062doy15SJ ._1rw8cklqOD2Iq2Aoisb868 {
    position: absolute;
    right: 15px;
    cursor: pointer; }
  .cU-I1Pv_wOH062doy15SJ ._3g8ttEuEcV8TT_RfPIDyN7,
  .cU-I1Pv_wOH062doy15SJ .lD9u0Iy-tTj7CgKnviOHB {
    display: none; }
  .cU-I1Pv_wOH062doy15SJ ._2ji3V7CdnAx0q8YlGxpXET {
    display: inline; }
  .cU-I1Pv_wOH062doy15SJ ._2gcA4klWVoChHs88LDuGyi {
    display: block; } }
