.XCJGeodijnrij5B2mM9Q {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
  text-align: center;
  overflow: hidden; }
  @media (max-width: 767px) {
    .XCJGeodijnrij5B2mM9Q {
      margin: 0; } }
  @media (min-width: 1200px) {
    .XCJGeodijnrij5B2mM9Q {
      max-width: 1149px;
      min-width: 1149px;
      margin: 0 auto; } }

.WmaDRXFonq0giMdOTS963 {
  position: relative;
  width: 100%;
  padding: 0 30px 0 20px; }
  @media (max-width: 767px) {
    .WmaDRXFonq0giMdOTS963 {
      padding: 0; } }

._13hrjrA1jUU8Pr_BEu7WlU {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  transform: translateY(-50%);
  font-size: 32px;
  left: 0;
  z-index: 100; }
  ._13hrjrA1jUU8Pr_BEu7WlU:focus {
    outline: 0; }
  ._13hrjrA1jUU8Pr_BEu7WlU:hover {
    opacity: 1; }

._1DSFNl6PHOe8n6eWSUReyf {
  position: absolute;
  top: 50%;
  display: block;
  color: #4A4A4A !important;
  cursor: pointer;
  opacity: .9;
  font-size: 32px;
  transform: translateY(-50%);
  right: 0;
  z-index: 100; }
  ._1DSFNl6PHOe8n6eWSUReyf:focus {
    outline: 0; }
  ._1DSFNl6PHOe8n6eWSUReyf:hover {
    opacity: 1; }

._3mJIOH78HEQA3RP-MKUt7c {
  display: none; }

._1WuoOYD6w6zaoHkta8q9vl {
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-end;
  transition-timing-function: linear; }

._3NaV49cDBYqcG-ZKz6RDnm {
  display: none; }

@media (max-width: 767px) {
  ._3mJIOH78HEQA3RP-MKUt7c {
    display: flex;
    margin: 0 4px;
    cursor: pointer; }
    ._3mJIOH78HEQA3RP-MKUt7c a {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #111;
      opacity: .2;
      transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1); }
    ._3mJIOH78HEQA3RP-MKUt7c a:hover {
      opacity: 1;
      background: #1075b1; }
    ._1TcR0d-8KXhrd2zZ_ZMtAt {
      opacity: .9;
      display: flex;
      margin: 0 4px;
      cursor: pointer; }
      ._1TcR0d-8KXhrd2zZ_ZMtAt a {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
        opacity: 1;
        background: #01A3E5; }
  ._3NaV49cDBYqcG-ZKz6RDnm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin: 20px 0;
    padding: 0; }
  ._1DSFNl6PHOe8n6eWSUReyf, ._13hrjrA1jUU8Pr_BEu7WlU {
    display: none; } }

._1WpQI7kZdEaFdNKDHykga6 {
  text-align: left; }

._2jxuphkEQN_GSdGmcrm_ZS {
  text-align: center; }

._1ef7SSgVyOI9Vb1x72SfQl {
  text-align: right; }

._3FUY3p2MJS7AVwdDlhnJAV {
  margin-top: 15px; }

._3tgvIEvcXImoX1cOwMuboa {
  margin-top: 15px; }
  ._3tgvIEvcXImoX1cOwMuboa ._19mStCayh48ikOgN3YowED {
    line-height: 24px;
    color: black;
    margin: 0; }

._3xwK1Ldth4AbxIn_sqbeLc {
  cursor: pointer; }

/* Hide the images by default */
._2DIYhCTIh0fOua8qL7HinZ {
  display: none; }
  @media (max-width: 767px) {
    ._2DIYhCTIh0fOua8qL7HinZ {
      display: block; } }

._3ID_FaCxt6ypsAaLm5XB9r {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px; }

._2niQ2Fhs8kqxMR4nmTVxna {
  height: 1px;
  border-bottom: 0.5px solid #0074B3;
  width: 150px;
  margin: auto;
  margin-bottom: 20px; }

._3rHeIuz8zap0U4lcDIY4uo {
  position: relative; }

._2zy5nozpWWy9RtOO9X8-8P {
  margin-top: 25px;
  height: 200px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 767px) {
    ._2zy5nozpWWy9RtOO9X8-8P {
      height: 400px;
      max-width: 400px;
      display: block;
      margin: 0 auto; } }

._1Zc2tUeUTWd0BDLcq1icLa {
  display: flex;
  align-content: stretch; }
  ._1Zc2tUeUTWd0BDLcq1icLa a {
    text-decoration: none;
    color: #687689; }
    ._1Zc2tUeUTWd0BDLcq1icLa a:hover {
      text-decoration: none;
      color: #687689; }
  ._1Zc2tUeUTWd0BDLcq1icLa ._24iuGCqUqd3uk3awJZiceP {
    padding: 0.7rem;
    text-align: left; }
  ._1Zc2tUeUTWd0BDLcq1icLa ._2IX3COzSL7pQcLBSyNQGrU {
    min-width: calc(23vw - 50px) !important;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
    transition: transform ease-in-out 0.25s; }
    @media (min-width: 1200px) {
      ._1Zc2tUeUTWd0BDLcq1icLa ._2IX3COzSL7pQcLBSyNQGrU {
        width: 248px !important;
        min-width: 248px !important; } }
    @media (max-width: 767px) {
      ._1Zc2tUeUTWd0BDLcq1icLa ._2IX3COzSL7pQcLBSyNQGrU {
        min-width: 100% !important;
        margin: 0; }
        ._1Zc2tUeUTWd0BDLcq1icLa ._2IX3COzSL7pQcLBSyNQGrU img {
          width: 100%; } }
    @media (max-width: 500px) {
      ._1Zc2tUeUTWd0BDLcq1icLa ._2IX3COzSL7pQcLBSyNQGrU {
        padding: 0 2px 0 2px; } }

._2IX3COzSL7pQcLBSyNQGrU {
  width: calc(23vw - 30px);
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
  transition: transform 1s; }
  @media (min-width: 1200px) {
    ._2IX3COzSL7pQcLBSyNQGrU {
      width: 266px !important; } }
  @media (max-width: 767px) {
    ._2IX3COzSL7pQcLBSyNQGrU {
      min-width: calc(100vw - 30px);
      display: inline-block;
      width: 100%;
      padding: 0 15px 0 0; }
      ._2IX3COzSL7pQcLBSyNQGrU img {
        width: 100%; } }
  @media (max-width: 500px) {
    ._2IX3COzSL7pQcLBSyNQGrU {
      padding: 0 2px 0 2px; }
      ._2IX3COzSL7pQcLBSyNQGrU:last-of-type {
        padding: 0 0 0 2px; } }

._2KPyWk-uoa7O5wIf56laq6 {
  width: 100%;
  height: 200px; }

div._2i1TDqgY1pX5lnLGUymDpl {
  text-align: left; }

div._2fVzQbKyzJ9C9VJ5xrWdX {
  text-align: center; }

div._1Mf4J5kcEqiybG5U0yxobg {
  text-align: right; }

._1Y9XPOLN8NuLQpHHJWjQ7b {
  background: #065784;
  color: #fff !important;
  font-family: Lato, sans-serif;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc;
  height: 44px; }
  ._1Y9XPOLN8NuLQpHHJWjQ7b:hover {
    background: #1084b5; }

._21GzkMBFJYNCSfxas-qASg {
  font-weight: bolder;
  font-size: 1rem;
  margin: 0 5px; }

.o81PRzBYi8JECW8h8A0kS {
  color: #aaa;
  font-weight: bold; }
  .o81PRzBYi8JECW8h8A0kS .Omr2JMKdVTZ3N4r2osz3D {
    color: #aaa;
    text-decoration: line-through;
    margin: 0 5px; }
    .o81PRzBYi8JECW8h8A0kS .Omr2JMKdVTZ3N4r2osz3D span {
      color: #aaa; }

._17lIVkWsTDSPLxYNQfP3iO {
  font-weight: bold; }

.J-1_98sZhE1tX10wqD4gD {
  margin: 15px 0;
  font-weight: bold;
  text-align: center; }

.LXcjF9Z5Amu_vdH3p-2bQ {
  display: none; }

._1S8ZaeB_YktgZYGWsDNIlG {
  width: 50%;
  padding: 12px; }

._2JUMpiWi1_xVmfsu2mFz35 {
  text-align: center;
  margin-top: 12px;
  margin-right: 10px;
  height: 50px; }
  @media (max-width: 767px) {
    ._2JUMpiWi1_xVmfsu2mFz35 {
      width: 50%;
      min-width: 240px;
      margin: 10px auto 0; } }
