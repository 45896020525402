._29ez1fk8jgMAmA2XwHTAn {
  display: none; }

.ZaEnlqlINvbCXtIxf-765 {
  position: relative; }

/* The dots/bullets/indicators */
._34xfPtHnB2YgLKYUWg5Pwd {
  text-align: center;
  margin-top: 15px; }

.ItCXQfp-R7n7Cz_A8WjeP,
.z9rB_0jRhnOSP7PMNEOcQ {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._2eSJgV8xzF9oMF34mF8l-L,
.ItCXQfp-R7n7Cz_A8WjeP:hover {
  background-color: #717171; }

/* Next & previous buttons */
.ThR_KrQB6lBJw8y-j9g-U,
.O_xT8dIbzh2CaghUJMSVP {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: 0.3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._3BDPaAIMJxklmd-HuFCzQ8 {
  left: 10px; }

.O_xT8dIbzh2CaghUJMSVP {
  right: 10px;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
.ThR_KrQB6lBJw8y-j9g-U:hover,
.O_xT8dIbzh2CaghUJMSVP:hover {
  color: #fff;
  text-decoration: none; }

._2vxEzTkTsyXmIo5DL_Yrla,
._1uhBjVgjBst66PT7Ga9IL1 {
  width: 100%;
  max-width: 100%; }

._1uhBjVgjBst66PT7Ga9IL1 {
  display: block; }

/* Hide the images by default */
._29ez1fk8jgMAmA2XwHTAn {
  display: none; }

._2J4RKo69xP8eX6du2vKwCU {
  display: block; }

/* Fading animation */
._2LeuUfSlQqlRaa2xk_5Lyo {
  -webkit-animation-name: _2LeuUfSlQqlRaa2xk_5Lyo;
  -webkit-animation-duration: 1.5s;
  animation-name: _2LeuUfSlQqlRaa2xk_5Lyo;
  animation-duration: 1.5s; }

@-webkit-keyframes _2LeuUfSlQqlRaa2xk_5Lyo {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes _2LeuUfSlQqlRaa2xk_5Lyo {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

._1akGoukbgkEVXR6t0JIQD7 {
  position: relative; }

._2lTe4xQn8RhnsQPUkmdoY9 {
  height: max-content; }

._1zh01AQAf5jdSgmMfPp5x- {
  overflow: hidden;
  position: relative; }
  ._1zh01AQAf5jdSgmMfPp5x- .ZaEnlqlINvbCXtIxf-765 {
    display: flex;
    overflow: visible;
    transition: transform 0.5s; }
    ._1zh01AQAf5jdSgmMfPp5x- .ZaEnlqlINvbCXtIxf-765 ._2lTe4xQn8RhnsQPUkmdoY9 {
      position: relative;
      min-width: 100%; }
