.D2kD3mKCFkVTt0E1omq7U {
  position: relative; }

._3PcqSE-k9kp9tJqbdHcRQl {
  text-align: center; }

.D2kD3mKCFkVTt0E1omq7U:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._3RlRs-ADv2RpRqoxdxTkPP {
  background-attachment: fixed !important;
  background-repeat: no-repeat; }
  ._3RlRs-ADv2RpRqoxdxTkPP .hyZOEAzhKtwbQ3B0rXuym {
    position: relative;
    z-index: 1;
    color: white; }

._1_p7NsKkHIEDgmvnsn0s5X * {
  text-align: right; }

.sVzkOwTbVc868LXmDq8oE * {
  text-align: center; }

._1B9IFEMphQivHcil4HO5vE * {
  text-align: left; }

._2q6LVDzTSA012K0V0TupJ7 * {
  word-wrap: break-word; }
