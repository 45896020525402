._1dwwpPt8iGrgzpqgS7F7nC {
  border: 1px solid lightgray;
  cursor: pointer; }
  ._1dwwpPt8iGrgzpqgS7F7nC:first-of-type {
    margin-top: 0px; }
  ._1dwwpPt8iGrgzpqgS7F7nC:last-of-type {
    margin-bottom: 0px; }

._1OIEREpUzqFHH_8xsay11a {
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }

._1dwwpPt8iGrgzpqgS7F7nC img {
  align-self: center; }

._3qi2UlYz_fV6dggLiD9FPd {
  opacity: 0.6;
  transition: 0.5s opacity; }

._3qi2UlYz_fV6dggLiD9FPd:hover {
  opacity: 1; }

._2tJqnWE-tJcMzxMZRGGxlD {
  object-fit: cover; }
  @media (max-width: 991px) {
    ._2tJqnWE-tJcMzxMZRGGxlD {
      max-height: 200px; } }
  @media (min-width: 991px) {
    ._2tJqnWE-tJcMzxMZRGGxlD {
      max-width: 100%; } }
  ._2tJqnWE-tJcMzxMZRGGxlD._3ZA_yECWSsN_yodXvVWPIY {
    max-height: 200px;
    max-width: 100%; }

.pha68VKlMUhTaoaATHaWD {
  display: flex; }

._1auANTVpos3QzdG5GJBFnR {
  width: 100%;
  display: flex; }

._1W7I9DsfmFMaxAQ9wdmDFJ {
  width: 100%;
  display: flex;
  flex-direction: column-reverse; }

._3OmrumbTu7F-qNvW6TmfIk i {
  align-self: center;
  width: 100%; }

._3OmrumbTu7F-qNvW6TmfIk > div {
  display: flex;
  flex-direction: column;
  align-items: center; }

._11XduOvvVwOCuHYDI2C8y6 > div {
  flex-direction: row; }

.T6l5Sh35YtVapKnc-tNAX {
  display: none !important; }

._1zGW0nnJtVYDSsFMKGAHqt {
  align-content: center;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex; }
  ._1zGW0nnJtVYDSsFMKGAHqt._3ZA_yECWSsN_yodXvVWPIY {
    align-items: center;
    justify-content: center !important; }
  @media (max-width: 768px) {
    ._1zGW0nnJtVYDSsFMKGAHqt {
      align-items: center; } }
  ._1zGW0nnJtVYDSsFMKGAHqt ._26GppzXKprpcWA73JKpK_q {
    margin: 0; }
    ._1zGW0nnJtVYDSsFMKGAHqt ._26GppzXKprpcWA73JKpK_q._3ZA_yECWSsN_yodXvVWPIY {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    @media (max-width: 991px) {
      ._1zGW0nnJtVYDSsFMKGAHqt ._26GppzXKprpcWA73JKpK_q {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  ._1zGW0nnJtVYDSsFMKGAHqt ._8gg7eM83sBnJWMOmC9tg8._3ZA_yECWSsN_yodXvVWPIY {
    display: flex !important;
    padding: 0 4px; }
  @media (max-width: 991px) {
    ._1zGW0nnJtVYDSsFMKGAHqt ._8gg7eM83sBnJWMOmC9tg8 {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px; } }

.PbzqI_xoufUg1J1mNnv6n {
  justify-content: flex-start !important;
  flex: auto !important; }
  .PbzqI_xoufUg1J1mNnv6n ._2tJqnWE-tJcMzxMZRGGxlD {
    max-width: 100%; }

._1da5sMEL79A24NqeoQqsPq path {
  fill: gray; }

._1da5sMEL79A24NqeoQqsPq:hover path {
  fill: darkgrey; }

._3P5TTfIZVxy5HXtV7hFa9w {
  cursor: pointer; }

@media (max-width: 991px) {
  ._3whvHB3SwGzdmk2t85glaH {
    margin-left: 0px !important; } }

@media (max-width: 991px) {
  ._2q0MgZ4aj143CeR94Hdo30 {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

._1JLPGg1SlnKohkZxDcIiN8:before {
  content: '\F144';
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 100px; }

._1U3d1Nng0r_nNyc5N6tclT {
  opacity: 1;
  transition: opacity 0.5s linear; }

@keyframes _2p4W5dFxcEBN5EGa7UHf0Q {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._1Qt8NskDQnJLTFeFm3zYoT {
  display: none; }

.Er8bl7fIDXpw6zP4BwXwg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 35px;
  width: auto !important; }

._2l3cwzO3R6ZmGMVgtJDict {
  position: relative;
  height: 100%; }
