._2VY0B1Mpz-ebw0HZJu2e1w {
  position: relative; }

.BiWFdn-_0l1XmiuEel4y2 {
  text-align: center; }

._2VY0B1Mpz-ebw0HZJu2e1w:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._1SFwENuietrByp8uRTU2nQ {
  background-attachment: fixed !important;
  background-repeat: no-repeat; }
  ._1SFwENuietrByp8uRTU2nQ .eUXDi59c3xJT6olPtzSBl {
    position: relative;
    z-index: 1;
    color: white; }

._1NgZzPFYCX6CoJwVA1NqwV * {
  text-align: right; }

._2j_zN4QVJ1lNouTN6OLE80 * {
  text-align: center; }

._1RD7gBOhSy_yur5AsrxbZH * {
  text-align: left; }

._2ECjm-Ij9lsE4GMTRQuufK * {
  word-wrap: break-word; }

.NBkNYWPB8yEhDTRTb24Nu {
  display: block;
  position: relative;
  max-width: 100%; }

._1GUug2p_h6UOtj8I29aDPv {
  display: flex;
  position: relative;
  max-width: 100%; }

@media screen and (max-width: 768px) {
  ._1GUug2p_h6UOtj8I29aDPv {
    flex-wrap: wrap;
    width: 100%; } }
