._3SNr-7Zw8mSjkFUjzPcB9B {
  display: none; }

.F-ic2uvRaBpeABVvHrKHW {
  position: relative; }

/* The dots/bullets/indicators */
._3IbjyeNGHKHPKv0jA3PQrU {
  text-align: center;
  margin-top: 15px; }

._2NEmXi5r8QLfjBntecPJZ,
._3Me6i1LBa0mfPykUKu8fhS {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._2V22CBW6FUNpsv56RcO8tX,
._2NEmXi5r8QLfjBntecPJZ:hover {
  background-color: #717171; }

/* Next & previous buttons */
._3ZNU1IQ8xFUxGd5ZwtecIM,
.kfF9gG4bKXc0IAB9l2OL7 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: 0.3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._32bQLgA1KjQPo-AGXXH9FJ {
  left: 10px; }

.kfF9gG4bKXc0IAB9l2OL7 {
  right: 10px;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
._3ZNU1IQ8xFUxGd5ZwtecIM:hover,
.kfF9gG4bKXc0IAB9l2OL7:hover {
  color: #fff;
  text-decoration: none; }

.ZriTv7osRcouKLjQ0Vb6U,
._3MMe59bDc-m0lIx4fxuWDN {
  width: 100%;
  max-width: 100%; }

._3MMe59bDc-m0lIx4fxuWDN {
  display: block; }

/* Hide the images by default */
._3SNr-7Zw8mSjkFUjzPcB9B {
  display: none; }

._3h3O8PJoER7-JOrTK6e2A6 {
  display: block; }

/* Fading animation */
.yP6Rzx-LFUNVO2GbRG6QI {
  -webkit-animation-name: yP6Rzx-LFUNVO2GbRG6QI;
  -webkit-animation-duration: 1.5s;
  animation-name: yP6Rzx-LFUNVO2GbRG6QI;
  animation-duration: 1.5s; }

@-webkit-keyframes yP6Rzx-LFUNVO2GbRG6QI {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes yP6Rzx-LFUNVO2GbRG6QI {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

._3WU435zkLhco0VisJmOADt {
  position: relative; }

._1iN5jBOBOHjgfIodpBfpc_ {
  height: max-content; }

._1iqOmojeuv6WVPUID5KSUf {
  overflow: hidden;
  position: relative; }
  ._1iqOmojeuv6WVPUID5KSUf .F-ic2uvRaBpeABVvHrKHW {
    display: flex;
    overflow: visible;
    transition: transform 0.5s; }
    ._1iqOmojeuv6WVPUID5KSUf .F-ic2uvRaBpeABVvHrKHW ._1iN5jBOBOHjgfIodpBfpc_ {
      position: relative;
      min-width: 100%; }
