._2BEThCokeSOrIPbr-9BWy4 {
  margin: 0 auto; }
  ._2BEThCokeSOrIPbr-9BWy4 h1,
  ._2BEThCokeSOrIPbr-9BWy4 h2,
  ._2BEThCokeSOrIPbr-9BWy4 h3,
  ._2BEThCokeSOrIPbr-9BWy4 h4,
  ._2BEThCokeSOrIPbr-9BWy4 h5,
  ._2BEThCokeSOrIPbr-9BWy4 h6,
  ._2BEThCokeSOrIPbr-9BWy4 p {
    margin-bottom: 15px; }
