._1TWTRMdic-I-I2qRDZE1Oy {
  width: 100%; }

._6fxGCfxP-bqsIFCrFHT4d {
  margin-top: 15px; }

._2rmXguFVrv1z38PjsfcaDp {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }

._3yTrT1ByMSiw80iuxEZpo8 {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px; }

._1hWqdwaBLPtPZz3h4T-avn {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px; }
