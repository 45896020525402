._11N4fDNROkhUnWM4ByEIEf {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 4rem 0 8rem;
  min-height: 500px; }

._1y0ZC6qODahd5mwWB6-PaB {
  padding: 0 1.5rem;
  text-align: center;
  padding: 0 50px;
  margin-bottom: 50px;
  width: 100%;
  position: relative; }

._1cwAWWmBRH4I66Dr4P2i7Q {
  font-style: italic;
  padding: 0 1.5rem;
  text-align: center; }

._70O9BAxy0RZiY9McSzePy {
  height: 8rem;
  position: absolute;
  bottom: 0; }

._1QCpoFlDGgKrzq6Mho6GWD {
  position: absolute;
  color: #b4b4b4;
  font-size: 4rem; }
  ._1QCpoFlDGgKrzq6Mho6GWD.fa-quote-left {
    left: 0;
    top: -4rem; }
  ._1QCpoFlDGgKrzq6Mho6GWD.fa-quote-right {
    right: 0;
    bottom: -4rem; }

@media only screen and (max-width: 767px) {
  ._11N4fDNROkhUnWM4ByEIEf {
    min-height: 300px; }
  ._70O9BAxy0RZiY9McSzePy {
    height: 5rem; }
  ._1QCpoFlDGgKrzq6Mho6GWD.fa-quote-left {
    left: -20px; }
  ._1QCpoFlDGgKrzq6Mho6GWD.fa-quote-right {
    right: -20px;
    bottom: -3rem; } }
