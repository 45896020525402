._2HgQGnwE0wwhkCxrfshhO7 {
  flex: 1; }
  ._2HgQGnwE0wwhkCxrfshhO7 .bcP_-Lyb9pFxi7orBQ8_m {
    text-align: left; }
  ._2HgQGnwE0wwhkCxrfshhO7 ._29QC4tXBHF7hib3nkCSDUd {
    text-align: center; }
  ._2HgQGnwE0wwhkCxrfshhO7 .FEP4nOm-cJkeyg58wlT1Y {
    text-align: right; }

._27JbtYEFDetSplGBNHMELc {
  width: 100%;
  cursor: pointer; }

._3KErm8PbRl_FhQG8QFWZCc {
  margin-top: 15px; }

.hEmumCeAZi1RYPP9WK9Mr {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1; }

/* Hide the images by default */
.MBtxRD6lZPI8iFC_EpV7Q {
  display: flex;
  position: relative; }
  .MBtxRD6lZPI8iFC_EpV7Q a {
    text-decoration: none;
    color: #687689; }
    .MBtxRD6lZPI8iFC_EpV7Q a:hover {
      text-decoration: none;
      color: #687689; }

/* The dots/bullets/indicators */
._2EUlijP63Tw2o6j6GUOInW {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._2hcl7zA_C3ay1oSftjwQvr,
._2EUlijP63Tw2o6j6GUOInW:hover {
  background-color: #717171; }

/* Next & previous buttons */
.CLs1mPn8cf7Ts8yZ44nKf,
._1_mjiPj5Bg2OZoRZscUjSJ {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: 0 5px;
  color: #687689 !important;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: 0.3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._1_mjiPj5Bg2OZoRZscUjSJ {
  right: 0;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
a.CLs1mPn8cf7Ts8yZ44nKf:hover,
a._1_mjiPj5Bg2OZoRZscUjSJ:hover {
  color: #fff;
  text-decoration: none; }

.buSmUBhGccrqXfFQHTHN6 {
  padding-bottom: 10px; }

@media (min-width: 768px) {
  ._2hOF-WCaJ4Q1YkVAuva_x5 {
    padding: 0 2px 0 2px; }
    ._2hOF-WCaJ4Q1YkVAuva_x5:first-of-type {
      padding: 0 2px 0 0; }
    ._2hOF-WCaJ4Q1YkVAuva_x5:last-of-type {
      padding: 0 0 0 2px; } }

._3DieVom8FeSv10y9UBm6T9 {
  transition: box-shadow 0.2s ease-out; }
  ._3DieVom8FeSv10y9UBm6T9:hover {
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1); }

._2cgeDxA8MDMIf7IczA9_Lm {
  max-width: 100%;
  max-height: 300px; }

/* Fading animation */
._27ID5dx3_7iWtQwQA_BvjU {
  -webkit-animation-name: _27ID5dx3_7iWtQwQA_BvjU;
  -webkit-animation-duration: 1.5s;
  animation-name: _27ID5dx3_7iWtQwQA_BvjU;
  animation-duration: 1.5s; }

._3JyBd-HPYHgNPtFIsrBKZR {
  display: none;
  text-align: center;
  margin-top: 15px; }

._1q63L7LhGR6sY4et76y9VA {
  display: none; }

._1fLYnaY2wbygsRxYofIqk_ {
  display: block; }

._3OZ3UDMjm1ALGf5GJyBSqO,
._3EeAqiHe4tC-uHSY_U91ON {
  display: block; }

@media (max-width: 768px) {
  ._3DieVom8FeSv10y9UBm6T9:hover {
    transition: unset; }
  ._2HgQGnwE0wwhkCxrfshhO7 {
    min-width: 100% !important;
    transition: transform ease-in-out 0.25s; }
  .hEmumCeAZi1RYPP9WK9Mr {
    width: 100%;
    display: inline-flex;
    justify-content: unset;
    overflow: hidden; }
  ._1mloSAQT08gN3F7wfs-9bg {
    display: block; }
  ._2OHdulyHPvtVOg1XQ7NHjB {
    display: block; }
  .pO3OsJHZKgoBs5Ehb4ct6 {
    display: flex;
    flex-flow: row wrap;
    min-width: 50% !important;
    padding: 8px !important;
    margin: 0 !important; }
  ._1n43QgkZvTCIjF3-MfckRt {
    min-width: 50% !important;
    padding: 8px !important;
    margin: 0 !important; }
  ._3OZ3UDMjm1ALGf5GJyBSqO,
  ._3EeAqiHe4tC-uHSY_U91ON {
    display: none; }
  ._1hFRdMApOQ6UVZbwdJ4QPa,
  ._2xKz-oMwjG6dHjEYif0se0 {
    display: block; }
  ._1q63L7LhGR6sY4et76y9VA {
    display: block; } }

@-webkit-keyframes _27ID5dx3_7iWtQwQA_BvjU {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes _27ID5dx3_7iWtQwQA_BvjU {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

._2AGfWrhCCLTYjE96nJSA7o {
  background-repeat: no-repeat; }
