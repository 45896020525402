._1kGyzEzlf7twBugHkhxNfT a {
  text-decoration: none; }

._1kGyzEzlf7twBugHkhxNfT ._3IsVuFudISh3NIj6R-eiKB {
  -webkit-appearance: none;
  background-color: white;
  color: black;
  outline: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-transform: initial !important;
  transition: .4s all; }

._2fKPPd4sVZqvC_wsX_plDX {
  display: inline-block; }
  ._2fKPPd4sVZqvC_wsX_plDX ._3IsVuFudISh3NIj6R-eiKB {
    background: none;
    padding: 0; }
  ._2fKPPd4sVZqvC_wsX_plDX * {
    color: #1075B1; }
