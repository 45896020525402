._2cRVRuhjCQwBIaMXk_vnJV {
  position: absolute;
  width: 100%;
  opacity: 0; }

._2SjuKn5wi2j35L0RsFlGck {
  position: relative; }

.VKOJyld-i8KlrCN8p0qZY {
  opacity: 1; }

/* Fading animation */
._37c_f_S6-Gg4sEuwNrhnya.KkUoTsAOGsJHdl6CZ-nh3 {
  opacity: 1;
  z-index: 2;
  -webkit-animation-name: _12aJzTTX5jLXtyAffTtO-h;
  -webkit-animation-duration: 1s;
  animation-name: _12aJzTTX5jLXtyAffTtO-h;
  animation-duration: 1s; }

@-webkit-keyframes _12aJzTTX5jLXtyAffTtO-h {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _12aJzTTX5jLXtyAffTtO-h {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

._37c_f_S6-Gg4sEuwNrhnya._2tJ2opgfBgqCLTzJTroQkm {
  -webkit-animation-name: _1nUQ20dpuivE0X3GgxZH1T;
  -webkit-animation-duration: 0.3s;
  animation-name: _1nUQ20dpuivE0X3GgxZH1T;
  animation-duration: 0.3s; }

@-webkit-keyframes _1nUQ20dpuivE0X3GgxZH1T {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _1nUQ20dpuivE0X3GgxZH1T {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/* Sticky styles */
._2KflFHpdTPAho_o_jP9YXV {
  position: fixed;
  width: 100%;
  top: 0; }

/* Vertical scrolling animation */
._2luJpJmSHQhYyXmpvxc_Zl {
  transform: translateY(-100%); }

._2luJpJmSHQhYyXmpvxc_Zl.KkUoTsAOGsJHdl6CZ-nh3 {
  transition: 1s ease-in-out;
  opacity: 1;
  transform: translateY(0); }

._2luJpJmSHQhYyXmpvxc_Zl._2tJ2opgfBgqCLTzJTroQkm {
  -webkit-animation-name: b-aDHphh5XTsz8Q2Im-eG;
  -webkit-animation-duration: 1.5s;
  animation-name: b-aDHphh5XTsz8Q2Im-eG;
  animation-duration: 1.5s; }

@keyframes b-aDHphh5XTsz8Q2Im-eG {
  0% {
    opacity: 1;
    transform: translateY(0); }
  50% {
    opacity: 0.5;
    transform: translateY(100%); }
  100% {
    opacity: 0;
    transform: translateY(100%); } }

/* Horizontal scrolling animation */
.KMCeQuz3yREr3_OfNF4Hi {
  transform: translateX(-100%); }

.KMCeQuz3yREr3_OfNF4Hi.KkUoTsAOGsJHdl6CZ-nh3 {
  transition: 3s ease-in-out;
  opacity: 1;
  transform: translateX(0); }

.KMCeQuz3yREr3_OfNF4Hi._2tJ2opgfBgqCLTzJTroQkm {
  -webkit-animation-name: _1vcsylRJhCrTCprHqbcZ-x;
  -webkit-animation-duration: 3s;
  animation-name: _1vcsylRJhCrTCprHqbcZ-x;
  animation-duration: 3s; }

@keyframes _1vcsylRJhCrTCprHqbcZ-x {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(100%); } }
