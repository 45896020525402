._2ipagbeXiQ7o_1q6l7Tcdg {
  display: flex;
  align-items: center;
  text-align: center; }
  ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 {
    margin: 0 auto;
    max-width: 1000px;
    color: black; }
    ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._3WeJBQmmpd7lIYc6NqvUXe {
      font-weight: normal;
      padding: 0 20px;
      font-size: 2rem;
      margin: 0 auto; }
    ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._3KbOlJ_TQiGn9AIUynAwxm {
      font-weight: normal;
      margin-bottom: 2em;
      font-size: 1.2rem;
      padding: 20px; }
      ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._3KbOlJ_TQiGn9AIUynAwxm a {
        color: black;
        font-weight: bold; }
    ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._1jagtjVJ1ofh1Y4JodrpIU {
      width: 100%; }

@media (max-width: 768px) {
  ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._3WeJBQmmpd7lIYc6NqvUXe {
    font-size: 1.6rem; }
  ._2ipagbeXiQ7o_1q6l7Tcdg ._2SmlJ__mEI0vFwMC4OhQ08 ._3KbOlJ_TQiGn9AIUynAwxm {
    font-size: 1rem; } }
