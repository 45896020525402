.MBOXdL-il7qQSYuj4ub-X {
  position: relative; }

.BC0_xSUpYJhUrQ4mra4vP {
  text-align: center; }

.MBOXdL-il7qQSYuj4ub-X:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._2S6Ate5k7p6kMNCUwrucm- {
  background-attachment: fixed !important;
  background-repeat: no-repeat; }
  ._2S6Ate5k7p6kMNCUwrucm- .H77MMTDxRapkdlVyXeBgx {
    position: relative;
    z-index: 1;
    color: white; }

._1KE0aoIa0tyk1zKnCKh-3U * {
  word-wrap: break-word; }
