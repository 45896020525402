._1xMEthx5WxlNPChlI383h6 {
  margin: 0;
  margin-bottom: 15px;
  color: #fc5d20; }

._27-Z2V9c1uX_0ZWvuNvh58 {
  padding: 15px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px; }
  ._27-Z2V9c1uX_0ZWvuNvh58:hover {
    background-color: #eee; }

._3t-9-TcxkmWY8kTEmM0t_f {
  clear: both;
  text-align: center;
  margin-top: 30px; }

._1ZmUudKJV_wI-yU1VG3BL {
  margin: 0 auto 30px;
  display: flex;
  flex-wrap: wrap; }

._3vez2Zi_tvdJlSL_q28xwV {
  flex: 1 0 33.33%;
  min-width: 260px; }

._2WJQTu0XiWO1ODcZW7lDgr {
  display: none; }
