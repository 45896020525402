._3sezb6WeKJQHX8ScuGKTt9 {
  width: 100%; }

.AGn-t7UTfxtLEdiRZnh5I {
  display: none; }

.UBCosBwCYwEmA2v4_au24 {
  display: block; }

/* Fading animation */
._2qZzBcEwnja8zsLHF7Wg_D {
  -webkit-animation-name: _2qZzBcEwnja8zsLHF7Wg_D;
  -webkit-animation-duration: 1.5s;
  animation-name: _2qZzBcEwnja8zsLHF7Wg_D;
  animation-duration: 1.5s; }

@-webkit-keyframes _2qZzBcEwnja8zsLHF7Wg_D {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes _2qZzBcEwnja8zsLHF7Wg_D {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }
